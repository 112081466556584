var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"dashboard"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 grid-margin"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('h4',{staticClass:"card-title"},[_vm._v("Facturas abiertas")]),_c('b-table',{attrs:{"responsive":"","items":_vm.items,"fields":_vm.fieldsTable},scopedSlots:_vm._u([{key:"cell(factura)",fn:function(data){return [_c('router-link',{attrs:{"to":'/open-tables/' +
                  data.item.mesa +
                  '|' +
                  data.item.sala +
                  '|' +
                  data.item.codcliente +
                  '|' +
                  data.item.numero}},[_vm._v(_vm._s(data.item.mesa + "|" + data.item.sala + "|" + data.item.codcliente + "|" + data.item.numero))])]}},{key:"cell(totalbruto)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.numeral(data.item.totalbruto).format("$ 0,0.00"))+" ")]}}])})],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }