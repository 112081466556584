<template>
  <section class="dashboard">
    <div class="row">
      <div class="col-12 grid-margin">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Facturas abiertas</h4>
            <b-table responsive :items="items" :fields="fieldsTable">
              <!-- A virtual composite column -->
              <template #cell(factura)="data">
                <router-link
                  :to="
                    '/open-tables/' +
                    data.item.mesa +
                    '|' +
                    data.item.sala +
                    '|' +
                    data.item.codcliente +
                    '|' +
                    data.item.numero
                  "
                  >{{
                    data.item.mesa +
                    "|" +
                    data.item.sala +
                    "|" +
                    data.item.codcliente +
                    "|" +
                    data.item.numero
                  }}</router-link
                >
              </template>
              <template #cell(totalbruto)="data">
                {{ numeral(data.item.totalbruto).format("$ 0,0.00") }}
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from "axios";
import numeral from "numeral";
import { mapGetters } from "vuex";
export default {
  name: "open-tables",
  data() {
    return {
      fieldsTable: [
        {
          key: "factura",
          label: "# Factura",
        },
        {
          key: "mesa",
          label: "Mesa",
          sortable: true,
        },
        {
          key: "totalbruto",
          label: "Precio",
          sortable: true,
        },
      ],
      items: [],
    };
  },
  computed: {
    ...mapGetters(["getOpenDate", "getCloseDate"]),
  },
  created() {
    this.getTransactions();
    this.$store.dispatch("setEvent", { name: "open_openTables" });
  },
  methods: {
    getTransactions() {
      axios("/api/reports/transacts/open", {
        params: {
          fromDate: this.getOpenDate,
          toDate: this.getCloseDate,
        },
      }).then((res) => {
        this.items = res.data.data;
      });
    },
    numeral,
  },
  watch: {
    getOpenDate() {
      this.getTransactions();
    },
  },
};
</script>